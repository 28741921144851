import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { slide_img } from "../../../assets/images";

const CoverSwiper = () => {
	const { width, height } = useWindowDimensions();
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box sx={{ width, height: height / 3.5 }}>
			<Swiper
				slidesPerView={matchDownMd ? 1 : 5}
				spaceBetween={3}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				style={{
					height: "100%",
					width: "100%",
				}}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className="swiper"
			>
				{slide_img.map((img, i) => {
					return (
						<SwiperSlide key={i}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									background: "#f1f1f1",
								}}
							>
								<img
									src={img}
									alt=""
									style={{
										width: "100%",
										height: "100%",
										backgroundSize: "cover",
										objectFit: "contain",
									}}
								/>
							</Box>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</Box>
	);
};

export default CoverSwiper;
