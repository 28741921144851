import slider_1 from './slider_1.jpeg';
import slider_2 from './slider_2.jpg';
import slider_3 from './slider_3.jpg';
import slider_4 from './slider_4.jpg';
import certificate from './certificate.jpg';
import slider_6 from './slider_6.jpg';
import slider_7 from './slider_7.jpg';

import show_1 from './show_1.jpeg';
import show_2 from './show_2.jpeg';
import show_3 from './show_3.jpeg';
import show_4 from './show_4.jpeg';
import recommendation from './recommendation.webp';

import president_one from './president_one.jpg';
import president_two from './president_two.jpg';
import president_three from './president_three.jpg';
import president_four from './president_four.jpg';

import viber_1 from './viber_1.jpg';
import viber_2 from './viber_2.jpg';
import viber_3 from './viber_3.jpg';
import viber_4 from './viber_4.jpg';
import viber_5 from './viber_5.jpg';
import viber_6 from './viber_6.jpg';
import viber_7 from './viber_7.jpg';
import viber_8 from './viber_8.jpg';
import viber_9 from './viber_9.jpg';
import viber_10 from './viber_10.jpg';
import viber_11 from './viber_11.jpg';
import viber12 from './viber12.jpg';
import viber13 from './viber13.jpg';
import viber14 from './viber14.jpg';
import viber15 from './viber15.jpg';
import viber16 from './viber16.jpg';
import viber17 from './viber17.jpg';
import viber18 from './viber18.jpg';
import viber19 from './viber19.jpg';
import viber20 from './viber20.jpg';
import viber21 from './viber21.jpg';
import viber22 from './viber22.jpg';
import viber23 from './viber23.jpg';
import whiteSeat from './whiteSeat.png';
export {
	whiteSeat,
	president_two,
	slider_1,
	slider_2,
	slider_3,
	slider_4,
	slider_6,
	slider_7,
	recommendation,
	show_1,
	show_2,
	show_3,
	show_4,
};

export const presidentData = [
	president_one,
	slider_6,
	certificate,
	president_three,
	president_four,
];
export const itemData = [
	slider_1,
	slider_2,
	slider_3,
	slider_4,
	slider_7,
	viber_1,
	viber_2,
	viber_3,
	viber_4,
	viber_5,
	viber_6,
	viber_7,
	viber_8,
	viber_9,
	viber_10,
	viber_11,
	viber12,
	viber13,
	viber14,
	viber15,
	viber16,
	viber17,
	viber18,
	viber19,
	viber20,
	viber21,
	viber22,
	viber23,
];

export const slide_img = [
	slider_4,
	slider_7,
	slider_1,
	president_two,
	president_three,
	president_four,
	president_one,
	slider_6,
	slider_2,
	slider_3,
	viber_8,
];
