import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

//to Localhost
const REACT_CLONE_URL = 'http://localhost:4000/graphql';
// To Online
const serverURL = '/graphql';
export const uploadLink = createUploadLink({
	uri: process.env.NODE_ENV === 'production' ? serverURL : REACT_CLONE_URL,
	credentials: 'include',
	headers: { 'Apollo-Require-Preflight': 'true' },
});

export const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('profile');
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
		});
	if (networkError) {
		console.log(`[Network errorsss]: ${networkError}`);
	}
});
