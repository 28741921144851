import React from 'react';
import { Slide } from '@mui/material';

export const TransitionModal = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export const checkEmail = (email) => {
	const filter = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
	if (filter) return true;
	else {
		alert('Please input a valid email address!');
		return false;
	}
};
